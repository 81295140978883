import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import Title from "../components/title";
import NewsIntro from "../components/news_intro";
import NewsPreview from "../components/news_preview";
import PageSEO from "../components/page_seo";

const Pager = styled.div`
  max-width: calc(1200px - 2rem);
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 58px;
  button {
    font-family: "Catamaran", sans-serif;
    color: var(--text);
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    border: none;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    &:hover {
      color: var(--pink);
    }
  }
`;

const PageNews = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityArtist {
        edges {
          node {
            surname
            first_name
            headshot {
              ...ImageWithPreview
            }
          }
        }
      }
      allSanityNews(sort: { fields: date, order: DESC }, limit: 10) {
        edges {
          node {
            title
            _rawBodyBlocks(resolveReferences: { maxDepth: 10 })
            body_blocks {
              _rawChildren
              _type
              list
              style
              children {
                _key
                _type
                text
                marks
              }
            }
            related_artists {
              surname
              first_name
              headshot {
                ...ImageWithPreview
              }
            }
            date(formatString: "Do MMMM YYYY")
            image {
              ...ImageWithPreview
              asset {
                publicUrl
              }
            }
          }
        }
      }
    }
  `);

  const articles = data.allSanityNews.edges;
  const all_artists = data.allSanityArtist.edges.map((n) => n.node);
  const first_article = articles[0];

  return (
    <>
      <PageSEO path="/news" />
      <Title noborder>News</Title>
      <NewsIntro all_artists={all_artists} article={first_article.node} />
      {articles.map((a, index) => {
        if (index === 0) {
          return <div key={index}></div>;
        } else {
          return (
            <NewsPreview
              all_artists={all_artists}
              key={index}
              article={a.node}
            />
          );
        }
      })}
      <Pager>
        <Link to="/archive/2">
          <button>← Older Posts</button>
        </Link>
      </Pager>
    </>
  );
};

export default PageNews;
