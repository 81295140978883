import React from 'react'
import { blockContentToPlainText } from 'react-portable-text'
import styled from 'styled-components'
import Image from 'gatsby-plugin-sanity-image'
import { Link } from 'gatsby'
import { slugify, artistname } from '../../lib/utils'

const Container = styled.div`
  max-width: calc(1200px - 2rem);
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 68px;
  h2 {
    text-align: center;
    a {
      text-decoration: none;
      font-family: Catamaran, sans-serif;
      color: var(--text);
      transition: all 0.25s ease-in-out;
      &:hover {
        color: var(--pink);
      }
    }
  }
  p {
    margin-top: 6px;
  }
  img {
    margin-top: 28px;
    border-radius: 14px;
  }
`

const Related = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    display: inline-block;
    font-family: Catamaran, sans-serif;
    span {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    a {
      font-family: Catamaran, sans-serif;
      text-decoration: none;
    }
    &:last-of-type span {
      display: none;
    }
  }
`

const NewsIntro = ({ article, all_artists }) => {

  const url = "/news/" + slugify(article.title);
  const { related_artists } = article;
  const mentioned_artists = [];
  const related_artist_names = related_artists && related_artists.map(r => artistname(r));
  const body = article._rawBodyBlocks ? blockContentToPlainText(article._rawBodyBlocks) : false;

  // array of all artist names
  all_artists.map(artist => {
    if ((body && body.includes(artistname(artist))) || article.title.includes(artistname(artist))) {
      if (!related_artist_names.includes(artistname(artist))) {
        mentioned_artists.push(artist)
      }
    }
  });


  return (
    <Container>
      <h2><Link to={url}>{article.title}</Link></h2>
      <p>{article.date}</p>

      <Related>

      {related_artists && related_artists.length > 0 &&
            related_artists.map((r, index) => {
              const artist_name = artistname(r);
              const artist_link = '/artists/' + slugify(artist_name);
              return (
                <li key={index}><Link to={artist_link}>{artist_name}</Link><span>|</span></li>
              )
            })
      }

      { mentioned_artists && mentioned_artists.length > 0 &&
            mentioned_artists.map((r, index) => {
              const artist_name = artistname(r);
              const artist_link = '/artists/' + slugify(artist_name);
              return (
                <li key={index}><Link to={artist_link}>{artist_name}</Link><span>|</span></li>
              )
            })
      }

      </Related>


      <Link to={url}>
        {article?.image && article?.image?.asset !== null &&
          <Image
            {...article.image}
            width={1200}
            height={390}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        }
      </Link>
    </Container>
  )
}

export default NewsIntro;
